import React from 'react';
import { getEnvironment } from '../../environment/Environment';
// Carousel
import Carousel from 'react-bootstrap/Carousel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const { useRef, useState, useEffect } = React;

function Footer(){
    const [environment,setEnvironment] = useState(getEnvironment());
    const [headers, setHeaders] = useState( { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': environment.apiDomainCors, 'Token': environment.apiToken }});
    const [data, setData] = useState(new FormData());
    const [showLoadingGif, setShowLoadingGif] = useState(false);
    const [inputValues, setInputValues] = useState({
       email: null,
       password: null
   });

    useEffect(() => {

    },[]);

    const onChange =(e) => {
       setInputValues({ ...inputValues, [e.target.id]: e.target.value });
    }


    return (
        <>
            <footer class="text-center text-lg-start footer-content">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="row">
                                <div class="col-lg-2 col-md-2 col-sm-12 content-logo-footer text-center">
                                    <img src={environment.appHome + "assets/images/logo/logo.png"} className="logo-footer" />
                                </div>
                                <div class="col-lg-10 col-md-10 col-sm-12 reserved text-center">
                                    © 2022 Invitación Social. All Rights Reserved
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-2 col-lg-3">
                            <div class="text-center txt-footer">
                                <a href="#" className="txt-terms">Términos y condiciones</a>
                            </div>
                        </div>
                        <div class="col-lg-1 col-md-1"></div>
                        <div class="col-sm-12 col-md-3 col-lg-2 content-icos">
                            <div class="row content-dsk">
                                <div class="col-sm-4 col-md-4 col-lg-2 content-ico-one">
                                    <a href="#"><img src={environment.appHome + "assets/images/icons/fb.png"} className="ico-socials" /></a>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-2 content-ico-two">
                                    <a href="#"><img src={environment.appHome + "assets/images/icons/tw.png"} className="ico-socials" /></a>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-2">
                                    <a href="#"><img src={environment.appHome + "assets/images/icons/ins.png"} className="ico-socials" /></a>
                                </div>
                            </div>
                            <div class="row content-mb">
                                <div class="col-sm-12 col-md-12 ">
                                    <a href="#" className="content-ico-one"><img src={environment.appHome + "assets/images/icons/fb.png"} className="ico-socials" /></a>
                                    <a href="#" className="content-ico-one"><img src={environment.appHome + "assets/images/icons/tw.png"} className="ico-socials" /></a>
                                    <a href="#"><img src={environment.appHome + "assets/images/icons/ins.png"} className="ico-socials" /></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
