import React, { useState, useEffect }  from 'react';
import Home from './components/views/Home';
import NotFound from './components/views/NotFound';
import { Switch, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { getEnvironment } from './environment/Environment';

function App() {
    const [environment, setEnvironment] = useState(getEnvironment());
    console.log("environment: ",environment);

    return (
        <div className="App">
            <Switch>
                <Route path={environment.appContextPath + "/not-found"} component={NotFound}/>
                <Route path={environment.appContextPath + "/"} component={Home}/>
            </Switch>
        </div>
    );
}

export default App;
