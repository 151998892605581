//para produccion
var appHttpsActive = 1;



var confProd = {
    appSubdomain: "",
    appDomain: "invitacion.social",
    appContext: "/",
    appContextPath: "",

    apiHome: "https://bl.invitacion.social/",
    appHomeInvitacionUserAdmin: "https://mi.invitacion.social/",
    apiToken: "AYGQF4CJhHFekkE",
    apiDomainCors: "https://invitacion.social/"

}


var confCurrent = {
    env: "PROD",
    appSubdomain: "",
    appDomain: "",
    appContext: "",
    appContextPath: "",
    appHome: "",
    appHomeInvitacionUserAdmin: "",
    apiHome: "",
    apiToken: "",
    apiDomainCors: "", //se setea lo mismo que API HOME pero son el contexto [protocolo-puerto-dominio] para que el CORS funcione
    imageStyleLoading: {
        width:"100px",
        margin:"300px 20px 20px 0px",
        height:"100px"
    }

}



export function getConfigCurrent() {
    confCurrent.appSubdomain = confProd.appSubdomain;
    confCurrent.appDomain = confProd.appDomain;
    confCurrent.appContext = confProd.appContext;
    confCurrent.appContextPath = confProd.appContextPath;
    confCurrent.appHomeInvitacionUserAdmin = confProd.appHomeInvitacionUserAdmin;

    if (confCurrent.appSubdomain != "") {
        confCurrent.appHome = (appHttpsActive == 0 ? "http://" :"https://" ) + confCurrent.appSubdomain + "." + confCurrent.appDomain + confCurrent.appContext;
    } else {
        confCurrent.appHome = (appHttpsActive == 0 ? "http://" :"https://" ) + confCurrent.appDomain + confCurrent.appContext;
    }

    confCurrent.apiHome = confProd.apiHome;
    confCurrent.apiToken = confProd.apiToken;
    confCurrent.apiDomainCors = confProd.apiDomainCors;

    return confCurrent;
}
