import React from 'react';
import { getEnvironment } from '../../environment/Environment';
// Carousel
import Carousel from 'react-bootstrap/Carousel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Footer from './Footer';

const { useRef, useState, useEffect } = React;

function NotFound(){
    const [environment,setEnvironment] = useState(getEnvironment());
    const [headers, setHeaders] = useState( { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': environment.apiDomainCors, 'Token': environment.apiToken }});
    const [data, setData] = useState(new FormData());
    const [showLoadingGif, setShowLoadingGif] = useState(false);
    const [inputValues, setInputValues] = useState({
       email: null,
       password: null
   });

    useEffect(() => {

    },[]);

    const onChange =(e) => {
       setInputValues({ ...inputValues, [e.target.id]: e.target.value });
    }


    return (
        <>
            <ul class="nav justify-content-center shadow nav-content">
                <a class="nav-link active" aria-current="page" href={environment.appHome}>
                    <img src={environment.appHome + "assets/images/logo/logo.png"} className="logo-header" />
                </a>
                {/*<li class="nav-item">
                    <a class="nav-link" href="#">Link</a>
                </li>
                <img src={environment.appHome + "assets/images/landing/body.png"} className="body-img" />
                */}
            </ul>
            <div className="container">
                <div className="text-center"> <br /><br />
                    <img src={environment.appHome + "assets/images/error/not_found.png"} className="img-not-found" />
                    <h1>Evento no encontrado</h1>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default NotFound;
