
// import { getConfigCurrent } from './EnvLocal';
//import { getConfigCurrent } from './EnvDev';
import { getConfigCurrent } from './EnvProd';


const confCurrent = getConfigCurrent();


export function getEnvironment() {
    return confCurrent;
}
