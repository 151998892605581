import React from 'react';
import { getEnvironment } from '../../environment/Environment';
// Carousel
import Carousel from 'react-bootstrap/Carousel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Footer from './Footer';
import $ from "jquery";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GifLoader from 'react-gif-loader';
const { useRef, useState, useEffect } = React;

function Home(){
    const [environment,setEnvironment] = useState(getEnvironment());
    const [headers, setHeaders] = useState( { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': environment.apiDomainCors, 'Token': environment.apiToken }});
    const [data, setData] = useState(new FormData());
    const [showLoadingGif, setShowLoadingGif] = useState(false);
    const [inputValues, setInputValues] = useState({
        name: null,
        firstLastName: null,
        email: null,
        password: null,
        passwordconfirm: null
   });
   const [showMenu, setShowMenu] = useState(false);
   const [isShowFormLogin, setIsShowFormLogin] = useState(false);
   const [isShowFormRegister, setIsShowFormRegister] = useState(false);
   const [isShowFormRecoverPassword, setIsShowFormRecoverPassword] = useState(false);

    useEffect(() => {

    },[]);

    const onChange =(e) => {
       setInputValues({ ...inputValues, [e.target.id]: e.target.value });
    }

    const openMenu = () => {
        if (showMenu) {
            setShowMenu(false);
            $("#navbarNav").removeClass('show');
        } else {
            setShowMenu(true);
            $("#navbarNav").addClass('show');
        }
    }

    const openFormLogin = () => {
        setIsShowFormLogin(true);
        setInputValues({ email: null, password: null });
    }
    const handleCloseModalLogin = () => {
        setIsShowFormLogin(false);
    }

    const openFormRegister = () => {
        setIsShowFormRegister(true);
        setInputValues({ email: null, password: null, passwordConfirm: null, name: null, firstLastName: null });
    }
    const handleCloseModalRegister = () => {
        setIsShowFormRegister(false);
    }

    const openFormRecoverPassword = () => {
        handleCloseModalLogin();
        setIsShowFormRecoverPassword(true);
        setInputValues({ email: null, password: null, passwordConfirm: null });
    }
    const handleCloseModalRecoverPassword = () => {
        setIsShowFormRecoverPassword(false);
    }


    const singIn = () => {
        console.log("singIn");
        setShowLoadingGif(true);
        const axios = require('axios');

        console.log("inputValues.email: ",inputValues.email);
        console.log("inputValues.password: ",inputValues.password);

        if (inputValues.email != null && inputValues.password != null) {

            data.append('email',inputValues.email);
            data.append('password',inputValues.password);

            axios.post(environment.apiHome + 'a/action/login/' + Date.now(), data, headers)
            .then(function (response) {
                console.log("response: ",response);
                setShowLoadingGif(false);
                if(response.data.success) {
                    if(response.data.metadata.id == 1) {
                        localStorage.setItem("jwt", response.data.data.dataLogin.jwt);
                        localStorage.setItem("email", response.data.data.dataLogin.email);
                        
                        postCrossDomainMessage(response.data.data.dataLogin);

                        // window.location.href = environment.appHomeInvitacionUserAdmin + "getlocalstorage";
                        setShowLoadingGif(false);
                    }
                } else {
                    setShowLoadingGif(false);
                    toast.warning("Hubo un error al validar los datos",{ position: toast.POSITION.TOP_RIGHT });
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Hubo un error por favor contacta al administrador",{ position: toast.POSITION.TOP_RIGHT });
                setShowLoadingGif(false);
            });
        } else {
            toast.warning("No debe haber campos vacíos",{ position: toast.POSITION.TOP_RIGHT });
        }
    }


    const onRegister = () => {
        console.log("onRegister");
        setShowLoadingGif(true);
        const axios = require('axios');

        console.log("name: ",inputValues.name);
        console.log("firstLastName: ",inputValues.firstLastName);
        console.log("email: ",inputValues.email);
        console.log("password: ",inputValues.password);
        console.log("passwordConfirm: ",inputValues.passwordConfirm);

        if (inputValues.name != null && inputValues.firstLastName != null && inputValues.email != null && inputValues.password != null && inputValues.passwordConfirm != null) {
            if (inputValues.password == inputValues.passwordConfirm) {

                data.append('name',inputValues.name);
                data.append('firstLastName',inputValues.firstLastName);
                data.append('email',inputValues.email);
                data.append('password',inputValues.password);

                axios.post(environment.apiHome + 'a/register/' + Date.now(), data, headers)
                .then(function (response) {
                    console.log("response: ",response);
                    setShowLoadingGif(false);
                    if(response.data.success) {
                        if(response.data.metadata.id == 1) {
                            toast.success("Datos guardados correctamente, ya puedes entrar con tu email",{ position: toast.POSITION.TOP_RIGHT });
                            setInputValues({ email: null, password: null, passwordConfirm: null, name: null, firstLastName: null });
                            setIsShowFormRegister(false);
                        } else if (response.data.metadata.id == 2) {
                            toast.info("Este email ya existe, por favor cámbialo",{ position: toast.POSITION.TOP_RIGHT });
                        }
                    } else {
                        toast.warning("Hubo un error al registrar los datos",{ position: toast.POSITION.TOP_RIGHT });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Hubo un error por favor contacta al administrador",{ position: toast.POSITION.TOP_RIGHT });
                    setShowLoadingGif(false);
                });
            } else {
                setShowLoadingGif(false);
                toast.warning("Las contraseñas no coinciden, por favor verifica",{ position: toast.POSITION.TOP_RIGHT });
            }
        } else {
            setShowLoadingGif(false);
            toast.warning("No debe haber campos vacíos",{ position: toast.POSITION.TOP_RIGHT });
        }
    }


    const onRecoverPassword = () => {
        console.log("onRecoverPassword");
        setShowLoadingGif(true);
        const axios = require('axios');

        console.log("email: ",inputValues.email);
        console.log("password: ",inputValues.password);
        console.log("passwordConfirm: ",inputValues.passwordconfirm);

        if ( inputValues.email != null && inputValues.password != null && inputValues.passwordconfirm != null) {
            if (inputValues.password == inputValues.passwordconfirm) {

                data.append('email',inputValues.email);
                data.append('password',inputValues.password);

                axios.post(environment.apiHome + 'a/recover/password/' + Date.now(), data, headers)
                .then(function (response) {
                    console.log("response: ",response);
                    setShowLoadingGif(false);
                    if(response.data.success) {
                        if(response.data.metadata.id == 1) {
                            toast.success("Datos guardados correctamente, ya puedes entrar con tu email",{ position: toast.POSITION.TOP_RIGHT });
                            setInputValues({ email: null, password: null, passwordConfirm: null, name: null, firstLastName: null });
                            setIsShowFormRecoverPassword(false);
                        } 
                    } else {
                        toast.warning("Hubo un error al registrar los datos",{ position: toast.POSITION.TOP_RIGHT });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Hubo un error por favor contacta al administrador",{ position: toast.POSITION.TOP_RIGHT });
                    setShowLoadingGif(false);
                });
            } else {
                setShowLoadingGif(false);
                toast.warning("Las contraseñas no coinciden, por favor verifica",{ position: toast.POSITION.TOP_RIGHT });
            }
        } else {
            setShowLoadingGif(false);
            toast.warning("No debe haber campos vacíos",{ position: toast.POSITION.TOP_RIGHT });
        }
    }


    function postCrossDomainMessage(msg) {
        console.log("postCrossDomainMessage");
        var win = document.getElementById('ifr').contentWindow;
        var obj = {
            jwt: msg.jwt,
            email: msg.email
         };
        //win.postMessage(msg, "http://192.168.8.57/");
        
        win.postMessage(JSON.stringify({key: 'storage', method: "set", data: obj}),environment.appHomeInvitacionUserAdmin);
        //win.postMessage("infosend", "http://192.168.8.57/");
        window.location.href = environment.appHomeInvitacionUserAdmin + "events/list";
      }

      window.onmessage = (event) => {
        // debugger;
         if (event.data) {
        var receivedData = event.data;
      }
          //console.log("receivedData: ",receivedData);
          //console.log("origin: ",event.origin);

    };

      
    /*
      window.onload = function() {
        var win = document.getElementById('ifr').contentWindow;
        var obj = {
            jwt: "abc",
            email: "email@g.com"
         };
        //win.postMessage("infosend", "http://192.168.8.57/");
    };
    */
     
    
    
      
    /***** code for render *****/

    return (
        <>
            <ToastContainer/>

            <div className={showLoadingGif ? "position_loading" : "display-none"}>
                <GifLoader
                    loading={showLoadingGif}
                    imageSrc={environment.appHome + "assets/images/gif/loading.gif"}
                    overlayBackground="rgba(0,0,0,0.5)"
                    imageStyle={environment.imageStyleLoading}
                />
            </div>

            <iframe style={{display:"none"}} src={environment.appHomeInvitacionUserAdmin+"localstorage.html"} id="ifr"></iframe>

            <nav class="navbar navbar-expand-lg navbar-light shadow">
                <div className="container">
                    <div className="nav-logo content-m"><img src={environment.appHome + "assets/images/logo/logo.png"} className="logo-header" /></div>
                    <button class="navbar-toggler" type="button" onClick={openMenu} data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse " id="navbarNav">
                        <div className="nav-logo content-d"><img src={environment.appHome + "assets/images/logo/logo.png"} className="logo-header" /></div>
                        <ul class="navbar-nav">
                            <li class="nav-item content-m">
                                <a class="nav-link tsxt-btn-login" onClick={ openFormLogin } role="button">
                                    Entrar
                                </a>
                            </li>
                            <li class="nav-item content-m">
                                <a class="nav-link tsxt-btn-login" onClick={ openFormRegister } role="button">
                                    Registrarse
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="content-d">
                        <a class="nav-link tsxt-btn-login" onClick={ openFormLogin } role="button">
                            Entrar
                        </a>
                    </div>
                    <div className="content-d">
                        <a class="nav-link tsxt-btn-login" onClick={ openFormRegister } role="button">
                            Registrarse
                        </a>
                    </div>
                </div>
            </nav>

            <div className="container">
                <div className="text-center content-cart"> <br /> <br />
                    <img src={environment.appHome + "assets/images/landing/carta_cerrada.png"} className="img-carta" />
                    <h1 className="txt-body">Próximamente...</h1>
                </div>
            </div>

            <Footer />

            <Modal show={isShowFormLogin} onHide={handleCloseModalLogin}>
                <Modal.Header closeButton><Modal.Title>Inicia sesión</Modal.Title></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <Form.Group className="mb-3">
                                <Form.Label>Correo electrónico</Form.Label><Form.Control type="text" className="inputs-invitates-w" id="email" onChange = {(e) => onChange(e)} />
                            </Form.Group>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <Form.Group className="mb-3">
                                <Form.Label>Contraseña</Form.Label><Form.Control type="password" className="inputs-invitates-w" id="password"  onChange = {(e) => onChange(e)} />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="text-center">
                            <p>¿No recuerdas tu contraseña? Recupérala <a className="link" onClick={ openFormRecoverPassword }>aquí</a></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-9"></div>
                        <div className="col-sm-12 col-md-12 col-lg-3">
                            <button type="button" className="btn btn-success" onClick={ singIn } >Entrar</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal show={isShowFormRegister} onHide={handleCloseModalRegister}>
                <Modal.Header closeButton><Modal.Title>Regístrate</Modal.Title></Modal.Header>
                <Modal.Body>
                    <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <Form.Group className="mb-3">
                            <Form.Label>Nombre</Form.Label><Form.Control type="text" className="inputs-invitates-w" id="name" onChange = {(e) => onChange(e)} />
                        </Form.Group>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <Form.Group className="mb-3">
                            <Form.Label>Apellido Paterno</Form.Label><Form.Control type="text" className="inputs-invitates-w" id="firstLastName" onChange = {(e) => onChange(e)} />
                        </Form.Group>
                    </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <Form.Group className="mb-3">
                                <Form.Label>Correo electrónico</Form.Label><Form.Control type="text" className="inputs-invitates-w" id="email" onChange = {(e) => onChange(e)} />
                            </Form.Group>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Contraseña</Form.Label><Form.Control type="password" className="inputs-invitates-w" id="password"  onChange = {(e) => onChange(e)} />
                            </Form.Group>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Confirmar contraseña</Form.Label><Form.Control type="password" className="inputs-invitates-w" id="passwordConfirm"  onChange = {(e) => onChange(e)} />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-9"></div>
                        <div className="col-sm-12 col-md-12 col-lg-3">
                            <button type="button" className="btn btn-success" onClick={ onRegister } >Registrarse</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={isShowFormRecoverPassword} onHide={handleCloseModalRecoverPassword}>
                <Modal.Header closeButton><Modal.Title>Recuperar contraseña</Modal.Title></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <Form.Group className="mb-3">
                                <Form.Label>Correo electrónico</Form.Label><Form.Control type="text" className="inputs-invitates-w" id="email" onChange = {(e) => onChange(e)} />
                            </Form.Group>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <Form.Group className="mb-3">
                                <Form.Label>Contraseña</Form.Label><Form.Control type="password" className="inputs-invitates-w" id="password"  onChange = {(e) => onChange(e)} />
                            </Form.Group>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <Form.Group className="mb-3">
                                <Form.Label>Confirmar contraseña</Form.Label><Form.Control type="password" className="inputs-invitates-w" id="passwordconfirm"  onChange = {(e) => onChange(e)} />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-9"></div>
                        <div className="col-sm-12 col-md-12 col-lg-3">
                            <button type="button" className="btn btn-success" onClick={ onRecoverPassword } >Cambiar contraseña</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default Home;
